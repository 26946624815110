import React from "react";
import "./VSurvellianceVideo.css";
import {Container} from "react-bootstrap";

export const VSurvellianceVideo = ({title, video, videoPoster, description}) => {
  return (
    <>
      <section className="video-surveillance space-bottom-small">
        <div className="video-surveillance-head space-top-small text-center position-relative bg-blue">
          <Container>
            <h2 className="text-white">{title}</h2>
          </Container>
          <svg className="curved-bg-bottom" width="1924" height="112" viewBox="0 0 1924 112" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1923.64 111.191H0.458984V0.94104C634.708 133.096 1289.39 133.096 1923.64 0.94104V111.191Z" fill="#fff" />
          </svg>
        </div>
        <Container>
          <div className="video-surveillance-video-wrapper bg-white">
            <div class="video-surveillance-video">
            <iframe width="100%" height="600" src="https://www.youtube.com/embed/Wn9ILCwsTgg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <p className="mb-0 mt-5">{description}</p>
          </div>
        </Container>
      </section>
    </>
  );
};
