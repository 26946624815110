import React from "react";
import "./VHero.css";
import HeroVideo from "../../assets/video/video.mov";
import {Container} from "react-bootstrap";
import {VButton} from "../VButton/VButton";

export const VHero = ({title, hasDescription, description, hasButton, img, hasVideo, curvedColor, className}) => {
  return (
    <>
      <section className={`hero ${className}`} style={{backgroundImage: `url(${img})`}}>
        {hasVideo && (
          <div className="hero-video-wrapper">
            <div className="hero-video">
              <video autoPlay loop playsInline muted src={HeroVideo}></video>
            </div>
          </div>
        )}
        <div className="hero-inner">
          <Container>
            <div className="hero-content">
              <h1 className="text-white mb-4">{title}</h1>
              {hasDescription && <p className="text-white fw-300 mb-5 pb-4">{description}</p>}
              {hasButton && <VButton isLink={true} path="mailto:contact@veesion.ae" text="Contact us" type="white" />}
            </div>
          </Container>
        </div>

      </section>
    </>
  );
};
