import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import VScrollToTop from "./Components/VScrollToTop/VScrollToTop";
import { Home } from "./Pages/Home/Home";
import { VHeader } from "./Components/VHeader/VHeader";
import { VFooter } from "./Components/VFooter/VFooter";
import { Stylesheet } from "./Pages/Stylesheet/Stylesheet";
import { Supermarkets } from "./Pages/Supermarkets/Supermarkets";
import { Pharmacies } from "./Pages/Pharmacies/Pharmacies";
import { Stores } from "./Pages/Stores/Stores";
import { Partners } from "./Pages/Partners/Partners";
import { BecomePartners } from "./Pages/BecomePartners/BecomePartners";
import { ContactUs } from "./Pages/ContactUs/ContactUs";
import Press from "./Pages/Press/Press";
function App() {
  return (
    <BrowserRouter>
      <VHeader />
      <VScrollToTop />
      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/supermarkets" element={<Supermarkets />} />
          <Route exact path="/pharmacies" element={<Pharmacies />} />
          <Route exact path="/stores" element={<Stores />} />
          <Route exact path="/partners" element={<Partners />} />
          <Route exact path="/become-partners" element={<BecomePartners />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/stylesheet" element={<Stylesheet />} />
          <Route exact path="/press" element={<Press />} />
        </Routes>
      </main>
      <VFooter />
    </BrowserRouter>
  );
}

export default App;
