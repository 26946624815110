import React from "react";
import { Accordion, Container } from "react-bootstrap";
import "./VAccordion.css";
const VAccordion = ({ accordionList, defaultActiveKey }) => {
  return (
    <Container>
      <div className="custom-accordion">
        <h2 className="text-center">Questions and answers</h2>
        <div className="accordion-container">
          <Accordion defaultActiveKey={defaultActiveKey}>
            {accordionList.map((accordion, index) => {
              return (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <span> {index < 9 ? `0${index + 1}` : index + 1}</span>
                    {accordion.heading}
                  </Accordion.Header>
                  <Accordion.Body>
                    {accordion.content.map((content, index) => {
                      return (
                        <p className="small" key={index}>
                          {content}
                        </p>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </Container>
  );
};

export default VAccordion;
