import React from "react";
import { VButton } from "../VButton/VButton";
import Logo from "../../assets/images/logo/logo.svg";
import { Link } from "react-router-dom";
import "./VHeader.css";
import { useState } from "react";
import { VIcons } from "../VIcons/VIcons";

export const VHeader = () => {
  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const handleNavbar = () => {
    if (!showNav) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setHeaderScrolled(true);
    } else {
      setHeaderScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <header className={headerScrolled ? "header-shadow" : ""}>
      <div className="container">
        <div className="header-inner">
          <Link to="/" className="header-logo-wrapper">
            <img src={Logo} alt="Veesion - XGEN FZCO National Ditributor" />
          </Link>
          <div className={`menu-item-wrapper  ${showNav && "open-menu"}`}>
            <div className="d-flex position-relative menu-link-wrap">
              <div className="video-survillance menu-item-container">
                <div className="d-flex menu-item">
                  <h6 className="text-uppercase mb-0 text-blue fw-700 menu-item-title">
                    Video Surveillance
                  </h6>
                  {VIcons.DownAero}
                </div>
                <div className="header-dropdown video-survillance-dropdown">
                  {/* <a href="/" className="h6 text-uppercase text-white">
                    Large Surfaces
                  </a> */}
                  <Link
                    onClick={handleNavbar}
                    to="/pharmacies"
                    className="h6 text-uppercase text-white"
                  >
                    Pharmacies
                  </Link>
                  <Link
                    onClick={handleNavbar}
                    to="/stores"
                    className="h6 text-uppercase text-white"
                  >
                    Retail Stores
                  </Link>
                  <Link
                    onClick={handleNavbar}
                    to="/supermarkets"
                    className="h6 text-uppercase text-white"
                  >
                    Supermarkets
                  </Link>
                </div>
              </div>
              <div className="press menu-item-container">
                <div className="d-flex menu-item">
                  <Link
                    to="/partners"
                    onClick={handleNavbar}
                    className="text-uppercase mb-0 text-blue fw-700 h6"
                  >
                    Partners
                  </Link>
                  {/* {VIcons.DownAero} */}
                </div>
                {/* <div className="header-dropdown press-dropdown">
                  <Link
                    to="/become-partners"
                    className="h6 text-uppercase text-white"
                  >
                    Become Partners
                  </Link>
                </div> */}
              </div>
              <div className="about menu-item-container">
                <div className="d-flex menu-item">
                  <Link
                    to="/become-partners"
                    onClick={handleNavbar}
                    className="text-uppercase mb-0 text-blue fw-700 h6"
                  >
                    become partners
                  </Link>
                  {/* {VIcons.DownAero} */}
                </div>
                {/* <div className="header-dropdown about-dropdown">
                  <a href="/" className="h6 text-uppercase text-white">
                    Blog
                  </a>
                  <a href="/" className="h6 text-uppercase text-white">
                    We are hiring
                  </a>
                </div> */}
              </div>
            </div>
            <Link to="mailto:contact@xgen.ae" onClick={handleNavbar}>
              <VButton
                className={"header-contact-btn"}
                text="Contact Us"
                type="blue"
              />
            </Link>
          </div>
          <button
            className={`hamburger ${showNav && "is-active"}`}
            id="hamburger"
            onClick={handleNavbar}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
        </div>
      </div>
    </header>
  );
};
