import React, { useState } from "react";
import "./VReadMore.css";
const VReadMore = ({ content, color, ReadMoreColor }) => {
  const [showFullDescription, setFullDescription] = useState(false);
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };
  const description = showFullDescription ? content : content.slice(0, 150);
  return (
    <p className={`${color} x-small read-more`}>
      {description}
      <span
        className={`${ReadMoreColor}`}
        onClick={() => showFullDescriptionHandler(1)}
      >
        Read {showFullDescription ? "less..." : "more..."}
      </span>
    </p>
  );
};

export default VReadMore;
