import React, { useState } from "react";
import "./VTheft.css";
import AntiTheftSystemVector from "../../assets/images/cover/anti-theft-system-vector.svg";
import Installation from "../../assets/images/icons/installation.svg";
import Alert from "../../assets/images/icons/alerts.svg";
import Arrest from "../../assets/images/icons/arrest.svg";
import { Container } from "react-bootstrap";
import VReadMore from "../VReadMore/VReadMore";

const VTheft = ({ data }) => {
  const { heading, contents, card1, card2, card3 } = data;

  return (
    <section className="space-top">
      <Container>
        <h2 className="fw-300 text-dark mb-4">{heading}</h2>
        {contents.map((content) => (
          <p>{content}</p>
        ))}
      </Container>
      <div className="anti-theft-system space-top-small text-center position-relative">
        <Container>
          <div className="position-relative">
            <img
              className="d-none d-lg-block"
              src={AntiTheftSystemVector}
              alt=""
              width="100%"
            />
            <div className="anti-theft-step anti-theft-step-1">
              <div className="anti-theft-step-inner">
                <img src={Installation} alt="" />
                <div className="anti-theft-step-content">
                  <p className="text-dark mb-4 fw-700">{card1.heading}</p>
                  <VReadMore
                    content={card1.content}
                    color="text-dark"
                    ReadMoreColor="text-blue"
                  />
                  <span className="h1 text-dark fw-800">01</span>
                </div>
              </div>
            </div>
            <div className="anti-theft-step anti-theft-step-2">
              <div className="anti-theft-step-inner">
                <img src={Alert} alt="" />
                <div className="anti-theft-step-content">
                  <p className="text-dark mb-4 fw-700">{card2.heading}</p>
                  <VReadMore
                    content={card2.content}
                    color="text-dark"
                    ReadMoreColor="text-blue"
                  />
                  <span className="h1 text-dark fw-800">02</span>
                </div>
              </div>
            </div>
            <div className="anti-theft-step anti-theft-step-3">
              <div className="anti-theft-step-inner">
                <img src={Arrest} alt="" />
                <div className="anti-theft-step-content">
                  <p className="text-dark mb-4 fw-700">{card3.heading}</p>
                  <VReadMore
                    content={card3.content}
                    color="text-dark"
                    ReadMoreColor="text-blue"
                  />
                  <span className="h1 text-dark fw-800">03</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <svg
          className="curved-bg-bottom"
          width="1924"
          height="112"
          viewBox="0 0 1924 112"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1923.64 111.191H0.458984V0.94104C634.708 133.096 1289.39 133.096 1923.64 0.94104V111.191Z"
            fill="#fff"
          />
        </svg>
      </div>
    </section>
  );
};

export default VTheft;
