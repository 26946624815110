import { Col, Container, Nav, Row } from "react-bootstrap";
import "./VChooseProgram.css";
import { VButton } from "../../../Components/VButton/VButton";
import { VIcons } from "../../../Components/VIcons/VIcons";
export const VChooseProgram = () => {
  return (
    <>
      <section className="choose-program-section space-top-small space-bottom-small">
        <Container>
          <Row>
            <Col xs={12}>
              <h2 className="fw-300 text-white mb-0 text-center">
                Choose the program that's right for you
              </h2>
            </Col>
            <div className="choose-program-row d-flex align-items-center">
              <div className="choose-program-col w-100 left-card">
                <div className="d-flex align-items-center cp-card-head">
                  <div className="cp-card-icon-box bg-white d-flex align-items-center justify-content-center">
                    {VIcons.IntroducerIcon}
                  </div>
                  <h4 className="fw-400 text-white mb-0">Introducer</h4>
                </div>
                <div className="cp-data">
                  <p className="fw-300 text-white">
                    Uncover sales opportunities and introduce Veesion to retail
                    stores that wish to deploy smart security.
                  </p>
                  <Nav as="ul" className="d-block">
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.CurrectIcon}
                      <span>Acquisition</span>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.WrongIcon}
                      <span>Sales cycle</span>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.WrongIcon}
                      <span>Support</span>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="choose-program-col w-100 center-card">
                <div className="d-flex align-items-center cp-card-head">
                  <div className="cp-card-icon-box bg-white d-flex align-items-center justify-content-center">
                    {VIcons.SalesIcon}
                  </div>
                  <h4 className="fw-400 text-white mb-0">Sales</h4>
                </div>
                <div className="cp-data">
                  <p className="fw-300 text-white">
                    Promote Veesion and manage the full sales cycle. Grow your
                    business providing your clients with an exceptional
                    solution.
                  </p>
                  <Nav as="ul" className="d-block">
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.CurrectIcon}
                      <span>Acquisition</span>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.WrongIcon}
                      <span>Sales cycle</span>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.WrongIcon}
                      <span>Support</span>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="choose-program-col w-100 right-card">
                <div className="d-flex align-items-center cp-card-head">
                  <div className="cp-card-icon-box bg-white d-flex align-items-center justify-content-center">
                    {VIcons.ExportIcon}
                  </div>
                  <h4 className="fw-400 text-white mb-0">Expert</h4>
                </div>
                <div className="cp-data">
                  <p className="fw-300 text-white">
                    Manage every phase of the client relationship. Close the
                    deal, deploy the software, train the staff, and support the
                    strategy.
                  </p>
                  <Nav as="ul" className="d-block">
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.CurrectIcon}
                      <span>Acquisition</span>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.WrongIcon}
                      <span>Sales cycle</span>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex align-items-center fw-300 text-white"
                    >
                      {VIcons.WrongIcon}
                      <span>Support</span>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
            <VButton
              isLink={true}
              path="/become-partners"
              text="become a partner"
              type="white"
              className="w-max-content mx-auto mt-5"
            />
          </Row>
        </Container>
      </section>
    </>
  );
};
