import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Banner_bt from "../../assets/images/banner/banner-bt.png";
import "./VBanner.css";
const VBanner = ({ content }) => {
  return (
    <section className="space-top">
      <Container className="bt-banner">
        <Row>
          <Col md={3}>
            <div className="ps-5  banner-img-container position-relative w-100 h-100">
              <img src={Banner_bt} />
            </div>
          </Col>
          <Col md={9} className="py-5">
            <h4 className="fw-400 ps-4 pe-5 text-white lh-base mb-0">{content}</h4>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VBanner;
