import React from "react";
import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import './VButton.css'

export const VButton = ({id, text, type, className, isLink, path}) => {
  return (
    <>
      <Button as={isLink ? Link : "button"} to={isLink && path} id={id} className={className} variant={type}>
        {text}
      </Button>
    </>
  );
};
