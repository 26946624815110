import Secure1 from "../src/assets/images/secure/secure1.png";
import Secure2 from "../src/assets/images/secure/secure2.png";

export let Country = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
];
export let Profession = [
  "Student",
  "Software developer",
  "Nurse practitioner ",
  "Physician assistant ",
  "Physical therapist ",
  "Financial manager ",
  "IT Manager ",
];

export let FAQ = [
  {
    heading: "How does our video surveillance system for supermarkets work?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it inconnection with the objective pursued by the installation of the system of surveillance.
The conservation is not superior to 30 days, except for legal proceedings in progress.`,
      `In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion only work as an anti-theft system or does it have other functionalities?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it inconnection with the objective pursued by the installation of the system of surveillance.
The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "How long is the legal retention period for video surveillance recordings?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it inconnection with the objective pursued by the installation of the system of surveillance.
The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion also sell video surveillance cameras for supermarkets?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it inconnection with the objective pursued by the installation of the system of surveillance.
The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
];

//pharmacy page data start

export const pharmacySurveillance = {
  heading: `The benefits of intelligent video surveillance for pharmacies`,
  card1: `Actively
fight against
theft `,
  card2: `Detect 
theft in real
time`,
  card3: `Discourage 
regular
theft`,
  contents: [
    `The amount of theft (more precisely, shrinkage)
is estimated at up to 3% of the sales turnover
of the point of sale. The installation of a
traditional video surveillance system certainly
contributes to limiting these acts, but the
dissuasive effect fades from year to year. It is
difficult for a security guard (when there is one)
to keep an eye on everything and spot all
suspicious acts. Less than 5% of thefts are
detected by an experienced agent.`,
    `Veesion is a 24/7 video operator that doesn't
take breaks or vacations and never gets tired.
Simply put, Veesion helps you maximize your
security spend.`,
  ],
};

export const pharmacyKeyFigures = [
  {
    heading: "3%",
    content: "Unwanted shrinkage represents up to 3% of sales",
  },
  {
    heading: "1/2",
    content: "1 theft out of 2 is committed by a regular customer",
  },
  {
    heading: "20+",
    content:
      "Veesion equips more than 1800 points of sale in all sectors and in more than 20 countries",
  },
];

export const pharmacySecure = {
  heading: `Secure your store with intelligent video surveillance`,
  card1: {
    imgSrc: Secure1,
    heading: "Intelligent anti-theft system",
    content: `In the field of video surveillance, Veesion has developed a powerful tool to
     help you optimize the security of your store. We offer you an intelligent
     anti-theft system based on artificial intelligence allowing you to detect suspicious gestures in real time.`,
  },
  card2: {
    imgSrc: Secure2,
    heading: "An innovative artificial intelligence system",
    content: `Veesion is the first gesture recognition software used to fight against theft. 
    We bring to your video protection system the best of Artificial intelligence to prevent 
    theft in real time.`,
  },
};

export const pharmacyCommitment = {
  heading: "Our commitments regarding video surveillance in Pharmacies",
  contents: [
    `Veesion's algorithmic processing is based on gestures only. Our technology does not use facial recognition, 
    customer tracking or identity registration. Therefore, we do not allow the recognition of physical or physiological
     characteristics, the deduction of emotions or
     the intentions of the person. Keeping the identity of your customer’s safe is one of Veesion's founding commitments.`,
  ],
  card1: `Veesion's technology only analyzes gestures, never
                      individuals`,
  card2: `Veesion does not use the data collected for its own
                      activities`,
  card3: `Artificial intelli-gence is put at the service of its
                      customers...`,
  card4: `Veesion technology has the same purpose as...`,
};

export const pharmacyVtheft = {
  heading: `How does our anti-theft system for pharmacies work?`,
  contents: [
    `Veesion is an intelligent anti-theft system that has been "trained" for several months by our developers and researchers 
    in artificial intelligence. They fed it with millions of examples of suspicious gestures to detect in pharmacies. Now, our software is able
     to detect the gestures of theft taking place in front of the surveillance cameras of your pharmacy.`,
    `The learning process of our artificial intelligence is continuous: this allows us to make our technology more and more efficient and to reach a 99% detection rate in the long run.`,
  ],
  card1: {
    heading: `Installation of the server containing the Veesion solution on the existing video surveillance system`,
    content: `Following the connection of the server containing our solution on your video recorder, we proceed to the
     synchronization of our software with the surveillance Read more...`,
  },
  card2: {
    heading: `Sending alerts on the tablet or phone provided by the supermarket`,
    content: `After being connected to your cameras, you
will be able to receive notifications on your
tablet, phone or checkout stations via the
Veesion app. These are`,
  },
  card3: {
    heading: `Arrest of the thieves by  security guards`,
    content: `Once the alert arrives on the phone or tablet, the security guard will be able to stop
     the thief before he flees. He can then use the video notification`,
  },
};

export let pharmacyAccordion = [
  {
    heading: "How does our video surveillance system for pharmacies work?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion only work as an anti-theft system or does it have other functionalities?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "How long is the legal retention period for video surveillance recordings?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion also sell video surveillance cameras for supermarkets?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
];

//pharmacy page data end

//supermarket page data start

export const superMarketSurveillance = {
  heading: `The benefits of intelligent video surveillance
for supermarkets`,
  card1: `Actively and
efficiently fight
against theft`,
  card2: `Detect
theft in real
time`,
  card3: `Discourage
theft by regular
customers`,
  contents: [
    `Depending on the region and the time of year,
shrinkage can represent up to 3% of super-
market sales. In these circumstances, the
installation of a traditional video surveillance
system can detect the theft, but its dissuasive
effect fades over time. In addition, it is difficult
for a security guard (if there is one) to detect
all suspicious acts. Less than 5% of thefts are
detected by trained agents.`,
    `Veesion is a video operator that never takes a
break or a vacation & never gets tired. Simply
put, Veesion helps you maximize your security
spend. Our software is available 24/7 & 365
days a year to detect suspicious actions at any
time and by anyone.`,
  ],
};

export const superMarketKeyFigures = [
  {
    heading: "3%",
    content: `Shrinkage represents
up to 3% of supermarket
sales`,
  },
  {
    heading: "1/2",
    content: `1 out of 2 thefts is
committed by a regular
customer`,
  },
  {
    heading: "20+",
    content: `Veesion equips more than
1800 points of sale in all sectors
and in more than 20 countries`,
  },
];

export const supermarketSecure = {
  heading: `Secure your supermarket with 
intelligent video surveillance`,
  card1: {
    imgSrc: Secure1,
    heading: "Intelligent anti-theft system",
    content: `In the field of video surveillance, Veesion has developed a powerful tool to
     help you optimize the security of your store. We offer you an intelligent
     anti-theft system based on artificial intelligence allowing you to detect suspicious gestures in real time.`,
  },
  card2: {
    imgSrc: Secure2,
    heading: "An innovative artificial intelligence system",
    content: `Veesion is the first gesture recognition software used to fight against theft. 
    We bring to your video protection system the best of Artificial intelligence to prevent 
    theft in real time.`,
  },
};

export const superMarketCommitment = {
  heading: "     Our commitments regarding video surveillance in supermarkets",
  contents: [
    `Veesion technology is based solely on algorithmic processing of gestures. We do not use facial recognition, 
    customer tracking, or identity registration, so our technology does not allow for the recognition of physical
    or physiological characteristics, or the deduction of emotions or intentions of people.Protecting the identity
     of our clients’ consumers is one of Veesion’s founding commitments.`,
  ],
  card1: `Veesion's technology only analyzes gestures, never individuals`,
  card2: `Veesion does not use the data collected for its own
                      activities`,
  card3: `Artificial intelli-gence is put at the service of its
                      customers...`,
  card4: `Veesion technology has the same purpose as...`,
};

export const superMarketVtheft = {
  heading: `How does our anti-theft system for supermarkets work?`,
  contents: [
    `Veesion is an anti-theft system dedicated to supermarkets worldwide. For several months, our developers and AI researchers
   "trained" the Veesion algorithm with millions of examples of suspicious gestures to detect in supermarkets. Our software is now able
    to detect thefts that take place in front of supermarket surveillance cameras. The AI learning process continues and allows us to make
    our technology more and more efficient and eventually reach 99% detection rate.`,
  ],
  card1: {
    heading: `Installation of the server containing the veesion solution on the store's existing cameras`,
    content: `After connecting our server to your video recorder, we synchronize the Veesion software with the
     cameras in your store. In less than 30 minutes you start receiving your first alerts.`,
  },
  card2: {
    heading: `Sending alerts on the tablet or phone provided by the supermarket`,
    content: `Once connected to your cameras, the system will send you notifications on the tablet, phone
     or computer (even on your cash registers) provided by Veesion.`,
  },
  card3: {
    heading: `Arrest of the thieves by the supermarket's security guards`,
    content: `After receiving the alert, the security guard will be able to apprehend the
     thief before he flees and use the video notification to avoid any outbursts: a proof`,
  },
};

export let supermarketAccordion = [
  {
    heading: "How does our video surveillance system for supermarkets work?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it
       inconnection with the objective pursued by the installation of the system of surveillance.`,
      `The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion only work as an anti-theft system or does it have other functionalities?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it
       inconnection with the objective pursued by the installation of the system of surveillance.`,
      `The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "How long is the legal retention period for video surveillance recordings?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it
       inconnection with the objective pursued by the installation of the system of surveillance.`,
      `The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion also sell video surveillance cameras for supermarkets?",
    content: [
      `The person in charge of the device must determine the duration of conservation of the images of its cameras and must do it
       inconnection with the objective pursued by the installation of the system of surveillance.`,
      `The conservation is not superior to 30 days, except for legal proceedings in progress. In fact, some images can be kept for a longer
period if this conservation is necessary for an investigation & to serve as proof relative to a security incident. The need for retention
is re-evaluated regularly.`,
    ],
  },
];

//supermarket page data end

//store page data start

export const storeSurveillance = {
  heading: `The benefits of intelligent video surveillance for stores.`,
  card1: `Fight
effectively
against theft`,
  card2: `Identify
thefts in real
time`,
  card3: `Dissuade
regular
theft`,
  contents: [
    `Depending on the region and the time of year,
shrinkage can represent up to 3% of super-
market sales. In these circumstances, the
installation of a traditional video surveillance
system can detect the theft, but its dissuasive
effect fades over time. In addition, it is difficult
for a security guard (if there is one) to detect
all suspicious acts. Less than 5% of thefts are
detected by trained agents.`,
    `Veesion has no bias, in other words, we detect
both old and young people, men and women
without distinction. This allows you to multiply
your chances of detecting fraudulent acts.`,
  ],
};

export const storeKeyFigures = [
  {
    heading: "3%",
    content: `Shrinkage
represents up to 3%
of annual sales`,
  },
  {
    heading: "1/2",
    content: `of theft is committed by regular
customers. Veesion helps eradicate
recurring theft`,
  },
  {
    heading: "20+",
    content: `Veesion equips more than 1800 customers in all sectors and more than 20 countries`,
  },
];

export const storeSecure = {
  heading: `Secure your store with 
intelligent video surveillance`,
  card1: {
    imgSrc: Secure1,
    heading: "Intelligent anti-theft system",
    content: `In the field of video surveillance, Veesion has developed a powerful tool to help you optimize 
    the security of your store. We offer you an intelligent anti-theft system based on artificial intelligence
     allowing you to detect suspicious gestures in real time by warning you every time someone is acting suspicious in your store.`,
  },
  card2: {
    imgSrc: Secure2,
    heading: "An innovative artificial intelligence system",
    content: `Veesion is the first gesture recognition software used to fight against theft. 
    We bring to your video protection system the best of Artificial intelligence to prevent 
    theft in real time.`,
  },
};

export const storeCommitment = {
  heading: "Our commitments regarding video surveillance in Pharmacies",
  contents: [
    `Veesion technology is based solely on algorithmic processing of gestures. 
    The company does not use facial recognition, customer tracking or identity registration.`,
    `Therefore, our technology does not allow for the recognition of physical or physiological
     characteristics, the deduction of emotions or intentions of people.`,
    `This is one of Veesion's founding commitments. This commitment allows us
     to protect the identity of all our clients' consumers.`,
  ],
  card1: `Veesion's technology only analyzes gestures, never
                      individuals`,
  card2: `Veesion does not use the data collected for its own
                      activities`,
  card3: `Artificial intelli-gence is put at the service of its
                      customers...`,
  card4: `Veesion technology has the same purpose as...`,
};

export const storeVtheft = {
  heading: `How does our anti-theft system for pharmacies work?`,
  contents: [
    `Veesion is an anti-theft system for stores around the world. For several months, our developers and AI researchers "trained" 
    the Veesion algorithm to detect theft by giving it millions of examples of suspicious gestures related to theft. Our software 
    is able to detect thefts that take place in front of store cameras. In AI,
     the continuous learning process allows our technology to get better and better and eventually reach 99% detection rate.`,
  ],
  card1: {
    heading: `Installation of the software on the store's existing cameras:`,
    content: `After connecting our server to your video recorder, we synchronize the Veesion software with the
     cameras in your store. In less than 30 minutes you start receiving your first alerts.`,
  },
  card2: {
    heading: `Sending alerts on the tablet or phone available to the store:`,
    content: `Once connected to your cameras, the system will
send you notifications on your tablet, phone or computer
(even on your cash registers). These notifications are
short videos allowing you to quickly `,
  },
  card3: {
    heading: `Arrest of the thieves by the supermarket's security guards`,
    content: `After receiving the alert, the security guard
will be able to apprehend the thief before
he flees and use the video notification to avoid
any outbursts: The video proof `,
  },
};

export let storeAccordion = [
  {
    heading: "Who can view the video surveillance images of a store?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "How long is the legal retention period for video surveillance recordings?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
  {
    heading:
      "Does Veesion only work as an anti-theft system or does it have other functionalities?",
    content: [
      `Today, Veesion allows the detection of gestures potentially associated with shoplifting. We are also working on another algorithm
capable of detecting fraud at traditional checkouts or at self-checkout.`,
      `The alerts can be displayed as a popup (in addition to the application) on a PC. Thanks to a customer backoffice (soon available),
the user can have a view of the Veesion results on all his points of sale by filtering by dates.`,
    ],
  },
  {
    heading: "Does Veesion also sell video surveillance cameras for stores?",
    content: [
      `It is up to the person in charge of the system to determine the duration of the conservation of the images
       of its cameras according to the objective pursued by the implementation of the video surveillance system.`,
      `Nevertheless, this period should not exceed 30 days, except in the case of legal proceedings in progress. Therefore, 
      it happens that certain images are kept longer when this is necessary for an investigation, and
       can be used as proof following a security incident. It should be noted that the need for conservation is re-evaluated regularly.`,
    ],
  },
];

//press page start
export let pressVideoPostList = [
  {
    videoSrc: `https://www.youtube.com/embed/bZhBWdlFyxA`,
    date: "12/06/2023",
    heading: `Stores turn to tech to stop shoplifters`,
  },
  {
    videoSrc: `https://www.youtube.com/embed/bZhBWdlFyxA`,
    date: "22/02/2021",
    heading: `Greens launches flagship store and format in Dundee`,
  },
  {
    videoSrc: `https://www.youtube.com/embed/bZhBWdlFyxA`,
    date: "01/05/2019",
    heading: `Retailers busting thieves with facial-recognition tech used by MSG’s James Dolan`,
  },
  {
    videoSrc: `https://www.youtube.com/embed/bZhBWdlFyxA`,
    date: "06/05/2022",
    heading: `Helping retailers to detect thefts in real time - Paris-based Veesion and its innovative AI-driven gesture recognition technology.`,
  },
  {
    videoSrc: `https://www.youtube.com/embed/bZhBWdlFyxA`,
    date: "30/11/2022",
    heading: `SERVICE CENTRE: Software headaches and AI targeting shoplifters`,
  },
];
//press page end
