import { Col, Container, Row } from "react-bootstrap";
import "./VGrowBetterTogether.css";
import { VIcons } from "../../../Components/VIcons/VIcons";
export const VGrowBetterTogether = () => {
  return (
    <>
      <section className="grow-better-together-section space-top-small space-bottom-small">
        <Container>
          <Row>
            <Col xs={12}>
              <h2 className="fw-300 text-dark mb-0 text-center section-title mx-auto">
                Grow better together
              </h2>
            </Col>
            <div className="grow-better-together-row d-flex">
              <div className="grow-better-together-col w-100 text-center">
                <div className="grow-better-ico d-flex align-items-center justify-content-center mx-auto">
                  <div className="grow-better-icon-wrapper">
                    {VIcons.ValueIcon}
                  </div>
                </div>
                <h4 className="fw-400 text-white">Provide immediate value</h4>
                <p className="fw-300 text-white mb-0">
                  Bring additional value to your clients by introducing them to
                  Artificial Intelligence, the future of security.
                </p>
              </div>
              <div className="grow-better-together-col w-100 text-center center-card">
                <div className="grow-better-ico d-flex align-items-center justify-content-center mx-auto">
                  <div className="grow-better-icon-wrapper">
                    {VIcons.BusinessIcon}
                  </div>
                </div>
                <h4 className="fw-400 text-white">Drive new business</h4>
                <p className="fw-300 text-white mb-0">
                  Broaden your service offerings and expand your sales potential
                  with attractive commission and recurring revenues.
                </p>
              </div>
              <div className="grow-better-together-col w-100 text-center right-card">
                <div className="grow-better-ico d-flex align-items-center justify-content-center mx-auto">
                  <div className="grow-better-icon-wrapper">
                    {VIcons.RelationshipsIcon}
                  </div>
                </div>
                <h4 className="fw-400 text-white">
                  Build lasting relationships
                </h4>
                <p className="fw-300 text-white mb-0">
                  Partner with a team that invests in your success. We're
                  committed to your business and provide
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
