import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VIcons } from "../VIcons/VIcons";
import Logo from "../../assets/images/logo/logo.svg";
import "./VFooter.css";
export const VFooter = () => {
  const { Email } = VIcons;
  return (
    <footer>
      <Container className="space-top space-bottom-small">
        <Row className="g-5">
          <Col xl={2} sm={6} xs={12} className="sector-wrap">
            <h6 className="footer-heading">Sector</h6>
            <ul>
              <li>
                <Link to="/pharmacies">Pharmacies</Link>
              </li>
              <li>
                <Link to="/supermarkets">Supermarkets</Link>
              </li>
              <li>
                <Link to="/stores">Retail Stores</Link>
              </li>
              <li>
                <Link to="#">Large Surfaces</Link>
              </li>
            </ul>
          </Col>
          <Col xl={2} sm={6} xs={12} className="menu-wrap">
            <h6 className="footer-heading">Menu</h6>
            <ul>
              <li>
                <Link to="/">Press</Link>
              </li>
              <li>
                <Link to="#">We are hiring</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </Col>
          <Col
            xxl={4}
            xl={3}
            sm={12}
            className="align-items-center footer-logo-wrap d-flex justify-content-center"
          >
            <img src={Logo} alt="logo" />
          </Col>
          <Col xxl={2} xl={3} sm={6} xs={12} className="contact-wrap">
            <h6 className="footer-heading">CONTACT US</h6>
            <ul className="social-media">
              <li>
                <a href="tel:+97142577550">
                  <span>{VIcons.Call}</span>+ 97 1425 77550
                </a>
              </li>
              <li>
                <a href="mailto:contact@veesion.io">
                  <span>{Email}</span>contact@veesion.io
                </a>
              </li>
            </ul>
          </Col>
          <Col xl={2} sm={6} xs={12} className="follow-wrap">
            <h6 className="footer-heading">Follow us</h6>
            <ul>
              <li>
                <a href="https://www.facebook.com/xgen.ae" target="_blank">
                  <span>{VIcons.Facebook}</span>Facebook
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="footer-copyright text-md-start text-center">
        <Row className="py-4 g-5">
          <Col md={6}>
            <span>© All rights reserved &nbsp; | &nbsp; Veesion</span>
          </Col>
          <Col md={6} className="text-md-end copyright-bottom-right">
            <span className="me-5">
              {" "}
              <a href="#">Legal </a>
            </span>
            <span>
              <a href="#">Privacy Policy </a>
            </span>
          </Col>
        </Row>
      </Container>
      <a href="https://wa.me/971501601490" target="_blank" className="whatsapp-link">
        <span>{VIcons.Whatsapp}</span>
      </a>
    </footer>
  );
};
