import React from "react";
import {VHero} from "../../Components/VHero/VHero";
import Hero from "../../assets/images/home/retail.jpg";
import VSurveillance from "../../Components/VSurveillance/VSurveillance";
import {storeSurveillance, storeKeyFigures, storeSecure, storeCommitment, storeVtheft, storeAccordion} from "../../Data";
import VKeyFigure from "../../Components/VKeyFigure/VKeyFigure";
import VSecure from "../../Components/VSecure/VSecure";
import VCommitment from "../../Components/VCommitment/VCommitment";
import VTheft from "../../Components/VTheft/VTheft";
import VAccordion from "../../Components/VAccordion/VAccordion";
import { VButtonBanner2 } from "../../Components/VButtonBanner/VButtonBanner2";
import {VSurvellianceVideo} from "../../Components/VSurvellianceVideo/VSurvellianceVideo";

export const Stores = () => {
  return (
    <>
      <VHero
        img={Hero}
        title="Video surveillance retail stores: Intelligent anti-theft system"
        curvedColor="#2F67F8"
        hasDescription={true}
        description="In-store video surveillance is essential for theft prevention.
        Discover our software, Veesion, which detects thefts automatically in real time."
        hasButton
      />
      <VSurvellianceVideo
        title="Intelligent video surveillance for stores"
        video="https://veesion.io/wp-content/uploads/2022/05/UKsupermarche%CC%81.mov"
        videoPoster="https://veesion.io/wp-content/uploads/2022/07/cover-videosurveillance.webp"
        description="Veesion is a Paris based security company that has developed a gesture recognition technology. Since 2018, we have been detecting gestures of theft in stores around the world, with a goal of eradicating shoplifting and theft.Our gesture recognition software leverages the latest advances in Deep Learning to automatically detect obvious theft gestures in real time at retail stores from existing surveillance cameras.Do you want to ensure the security of your store? Our anti-theft system is for you!"
      />
      <div className="space-bottom-small">
        <VButtonBanner2
          title="Find out what Veesion can do for you"
          description="Do you have one or more pharmacies?Our team will get back to you within 48 hours."
        />
      </div>
      <VSurveillance data={storeSurveillance} />
      <VKeyFigure data={storeKeyFigures} />
      <VTheft data={storeVtheft} />
      <VSecure data={storeSecure} />
      <VCommitment data={storeCommitment} />
      <VAccordion accordionList={storeAccordion} defaultActiveKey={0} />
    </>
  );
};
