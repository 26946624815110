import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Secure1 from "../../assets/images/secure/secure1.png";
import Secure2 from "../../assets/images/secure/secure2.png";
import "./VSecure.css";
const Secure = ({ data }) => {
  const { heading, card1, card2 } = data;
  return (
    <div className="secure-section space-top space-bottom-small">
      <Container>
        <h2 className="text-center fw-300 mb-5 pb-5 text-dark">{heading}</h2>
        <Row className="pb-5 mb-2 borderbt position-relative g-5">
          <Col md={3} className="text-center">
            <img src={card1.imgSrc} alt="secure" />
          </Col>
          <Col
            md={9}
            className=" d-flex justify-content-center flex-column ps-5"
          >
            <h4 className="text-dark fw-400">{card1.heading}</h4>
            <p className="mt-4 mb-0">{card1.content}</p>
          </Col>
        </Row>
        <Row className="mt-5 pt-2 g-5">
          <Col md={3} className="text-center order-md-2">
            <img src={card2.imgSrc} alt="secure" />
          </Col>
          <Col
            md={9}
            className=" d-flex  justify-content-center align-items-md-end flex-column order-md-1"
          >
            <h4 className="text-dark fw-400">{card2.heading}</h4>
            <p className="text-md-end">{card2.content}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Secure;
