import React from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import { VButton } from "../../Components/VButton/VButton";
import { Maximize, Monitor, TickCircle, Video, Zoom } from "iconsax-react";
import WorldMap from "../../assets/images/cover/world-map.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation, Scrollbar } from "swiper";
import "./Home.css";
import StoreImg from "../../assets/images/testimonials/grocery-store.jpg";
import CompanyImg from "../../assets/images/testimonials/testimonial.jpg";
import { VHero } from "../../Components/VHero/VHero";
import VForm from "../../Components/VForm/VForm";
import Star from "../../assets/images/icons/star.svg";
import StoreSlideImg from "../../assets/images/cover/store-slide.jpg";
import WarehouseSlideImg from "../../assets/images/cover/warehouse-slide.jpg";
import pharmacies from "../../assets/images/home/Parmacie.jpeg";
import retail from "../../assets/images/home/retial.jpg";
import supermarket from "../../assets/images/home/supermarket.jpeg";
import { VButtonBanner } from "../../Components/VButtonBanner/VButtonBanner";
import { VButtonBanner2 } from "../../Components/VButtonBanner/VButtonBanner2";
import OurFeatureImg from "../../assets/images/cover/our-features.png";

export const Home = () => {
  const testimonials = [
    {
      img: StoreImg,
      companyImg: CompanyImg,
      name: "Siva P.",
      designation: "Manager Nisa",
      reviewText:
        "We’ve stopped 15 shoplifters in two weeks. I’ve got a feeling word has spread fast that they’ll get noticed if they try to shoplift here.",
    },
    {
      img: StoreImg,
      companyImg: CompanyImg,
      name: "Andy P.",
      designation: "owner at nisa",
      reviewText:
        "We needed something that allowed us to be everywhere at once, even when staff were thin on the ground. Veesion allows us to do exactly that. It was effective from the very first day we installed it and is only going to get better as time goes on.",
    },
    {
      img: StoreImg,
      companyImg: CompanyImg,
      name: "Indie R.",
      designation: "franchisee at budgens",
      reviewText:
        "I was able to triple the amount of thefts stopped, and provide my security guard a tool that made him more effective.",
    },
    {
      img: StoreImg,
      companyImg: CompanyImg,
      name: "Toni B.",
      designation: "OWNER AT LONDIS",
      reviewText:
        "Staff stress has been reduced. Now, we are able to concentrate on the business with peace of mind.",
    },
  ];

  return (
    <>
      <VHero
        className="home-hero"
        hasVideo={true}
        title={
          <>
                     <br />
             <br />
            <br />
           <br />
             <br />
            <br />
          </>
        }
        curvedColor="#fff"
      />
      <section className="our-features space-top">
        <Container>
          <Row className="g-5 justify-content-center text-center mb-5 pb-5">
            <Col lg={8}>
              <h6 className="text-blue text-uppercase fw-700 mb-4">
                Veesion, The Software that continuously Analyzes your Video
                feeds
              </h6>
              <h2 className="fw-400 text-dark mb-0">
                Join store owners who effectively combat theft using Veesion
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md={6}>
              <div className="our-feature-head">
                <div className="our-feature-title">
                  <p className="text-white text-uppercase">Our</p>
                  <span className="h1 text-white mb-0">Features</span>
                </div>
                <img className="our-feature-img" src={OurFeatureImg} alt="" />
              </div>
            </Col>
            <Col md={5}>
              <div className="our-feature-circle-wrapper ms-xl-5 ps-xl-5">
                <svg
                  className="our-feature-arrow d-lg-block d-none"
                  width="295"
                  height="180"
                  viewBox="0 0 295 180"
                  fill="none"
                >
                  <path
                    d="M162 111L161.674 111.676L162 111ZM142.5 147.5L143.222 147.299L142.5 147.5ZM295 4.5L287.273 0.589507L287.75 9.23662L295 4.5ZM1.64345 164.385C10.1389 150.199 32.525 128.483 61.648 114.59C90.7488 100.708 126.459 94.6892 161.674 111.676L162.326 110.324C126.575 93.0798 90.368 99.2277 61.0021 113.236C31.6585 127.235 9.02776 149.135 0.35655 163.615L1.64345 164.385ZM161.674 111.676C182.826 121.878 193.254 133.536 196.845 144.085C200.426 154.608 197.25 164.153 190.914 170.338C184.563 176.537 175.052 179.349 165.959 176.381C156.884 173.419 148.052 164.637 143.222 147.299L141.778 147.701C146.698 165.363 155.803 174.644 165.494 177.807C175.167 180.964 185.25 177.963 191.961 171.412C198.687 164.847 202.043 154.704 198.265 143.602C194.496 132.527 183.674 120.622 162.326 110.324L161.674 111.676ZM143.222 147.299C134.34 115.413 152.165 81.5497 181.597 54.7964C211.004 28.0658 251.791 8.64152 288.317 5.61951L288.193 4.12462C251.287 7.1781 210.199 26.7705 180.588 53.6864C151.002 80.5795 132.666 114.993 141.778 147.701L143.222 147.299Z"
                    fill="#828491"
                  />
                </svg>
                <div className="our-feature-circle our-feature-circle-1">
                  <svg
                    width="45"
                    height="85"
                    viewBox="0 0 45 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.24 0H28.56L1.8 8.64V21.6L16.08 17.04V71.4H0V84.48H44.64V71.4H30.24V0Z"
                      fill="url(#paint0_linear_64_630)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_64_630"
                        x1="22.32"
                        y1="0"
                        x2="22.32"
                        y2="84.48"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2F67F8" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p className="mb-0 text-dark mt-md-5">
                    Algorithmic analysis of video footage
                  </p>
                </div>
                <div className="our-feature-circle our-feature-circle-2">
                  <svg
                    width="51"
                    height="64"
                    viewBox="0 0 51 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.44 47.97C10.44 42.12 16.11 38.52 25.56 38.52C41.85 38.52 50.58 31.86 50.58 19.26C50.58 7.38 41.22 0 26.1 0C10.62 0 1.08 7.02 0.36 18.9H11.16C11.43 13.14 17.1 9.45 26.1 9.45C34.83 9.45 39.96 13.23 39.96 19.44C39.96 25.2 34.11 29.07 25.47 29.07C9.36 29.07 0 36 0 47.97V63.81H50.58V54H10.44V47.97Z"
                      fill="url(#paint0_linear_64_634)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_64_634"
                        x1="25.29"
                        y1="0"
                        x2="25.29"
                        y2="63.81"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2F67F8" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p className="small mb-0 text-dark mt-md-5">
                    Automatically detects suspicious gestures
                  </p>
                </div>
                <div className="our-feature-circle our-feature-circle-3">
                  <svg
                    width="40"
                    height="50"
                    viewBox="0 0 40 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.1528 24.375C36.0417 22.9861 38.8195 18.8889 38.8195 14.2361C38.8195 10 37.0833 6.59722 33.6111 3.95833C30.0694 1.31944 25.5556 0 19.9306 0C14.1667 0 9.51389 1.25 6.04167 3.81944C2.56944 6.38889 0.833333 9.86111 0.833333 14.0972H8.88889C8.88889 10.1389 13.2639 7.5 19.8611 7.5C26.3194 7.5 30.7639 10.2083 30.7639 14.3056C30.7639 18.4722 26.3889 21.0417 19.7222 21.0417H12.0139V27.7778H20.625C27.5694 27.7778 31.6667 30.4167 31.6667 35.0694C31.6667 39.7222 27.1528 42.5 20.2778 42.5C16.8056 42.5 13.8889 41.8056 11.5972 40.4167C9.30556 39.0972 8.125 37.3611 8.125 35.3472H0C0 39.7917 1.80556 43.3333 5.41667 45.9722C8.95833 48.6806 13.75 50 19.7222 50C25.9028 50 30.7639 48.6806 34.4444 45.9722C38.125 43.3333 39.9306 39.7917 39.9306 35.2778C39.9306 30.0694 37.0833 26.1111 32.1528 24.375Z"
                      fill="url(#paint0_linear_64_638)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_64_638"
                        x1="19.9653"
                        y1="0"
                        x2="19.9653"
                        y2="50"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2F67F8" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p className="x-small mb-0 text-dark mt-4">
                    Receive a video notification in real time
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="space-top space-bottom-small">
        <Container>
          <Row className="g-5 justify-content-center text-center">
            <Col lg={7}>
              <h6 className="text-blue text-uppercase fw-700 mb-4">
                SIGNIFICANTLY REDUCE YOUR SHRINKAGE.
              </h6>
              <h2 className="fw-400 text-dark mb-0">
                Join store owners who effectively combat theft using Veesion
              </h2>
            </Col>
          </Row>
        </Container>
        {/* Slider start */}
        <div className="store-slider-container">
          <Swiper
            grabCursor={true}
            slidesPerView={3}
            spaceBetween={-25}
            loop={true}
            autoplay={{
              delay: 2000,
            }}
            effect="coverflow"
            coverflowEffect={{
              rotate: 0,
              depth: 1900,
              modifier: 0.1,
              slideShadows: false,
              stretch: -1690,
            }}
            navigation={{
              prevEl: `.prev-btn`,
              nextEl: `.next-btn`,
            }}
            scrollbar={{
              hide: false,
              dragSize: 221,
              snapOnRelease: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 1,
                coverflowEffect: {
                  stretch: -950,
                  depth: 1000,
                },
              },
              769: {
                coverflowEffect: {
                  stretch: -900,
                  depth: 1000,
                },
              },
              1024: {
                coverflowEffect: {
                  stretch: -900,
                  depth: 1000,
                },
              },
              1400: {
                coverflowEffect: {
                  stretch: -1000,
                  depth: 1000,
                },
              },
              1600: {
                coverflowEffect: {
                  stretch: -1690,
                  depth: 1900,
                },
              },
            }}
            modules={[Scrollbar, EffectCoverflow, Navigation, Autoplay]}
            className="store-slider-wrap"
          >
            <SwiperSlide>

              <a href={"/supermarkets"}><img src={WarehouseSlideImg} alt="store-img" className="slide-img" /></a>
              <div className="slider-text-wrap">
                <h3 className="text-dark fw-400 text-center">Large Surfaces</h3>
                <p className="small text-gray fw-400 text-center">
                  Install Veesion in your Food Logistics and receive alerts on
                  your cash registers.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <a href={"/pharmacies"}> <img
                src={pharmacies}
                alt="warehouse-img"
                className="slide-img"
              /></a>
              <div className="slider-text-wrap">
                <h3 className="text-dark fw-400 text-center">Pharmacies</h3>
                <p className="small text-gray fw-400 text-center">
                  Install Veesion in your Food Logistics and receive alerts on
                  your cash registers.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <a href={"/stores"}> <img
                src={retail}
                alt="cosmatics-store-img"
                className="slide-img"
              /></a>
              <div className="slider-text-wrap">
                <h3 className="text-dark fw-400 text-center">Retail stores</h3>
                <p className="small text-gray fw-400 text-center">
                  Install Veesion in your Food Logistics and receive alerts on
                  your cash registers.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <a href={"/supermarkets"}><img
                src={supermarket}
                alt="cosmatics-store-img"
                className="slide-img"
              /></a>
              <div className="slider-text-wrap">
                <h3 className="text-dark fw-400 text-center">Supermarkets</h3>
                <p className="small text-gray fw-400 text-center">
                  Install Veesion in your Food Logistics and receive alerts on
                  your cash registers.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <a href={"/supermarkets"}> <img src={WarehouseSlideImg} alt="store-img" className="slide-img" /></a>
              <div className="slider-text-wrap">
                <h3 className="text-dark fw-400 text-center">Large Surfaces</h3>
                <p className="small text-gray fw-400 text-center">
                  Install Veesion in your Food Logistics and receive alerts on
                  your cash registers.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <a href={"/pharmacies"}> <img
                src={pharmacies}
                alt="warehouse-img"
                className="slide-img"
              /></a>
              <div className="slider-text-wrap">
                <h3 className="text-dark fw-400 text-center">Pharmacies</h3>
                <p className="small text-gray fw-400 text-center">
                  Install Veesion in your Food Logistics and receive alerts on
                  your cash registers.
                </p>
              </div>
            </SwiperSlide>
            <div className="slider-btn-wrap">
              <VButton
                className={`prev-btn btn btn-blue`}
                text={"previous"}
              />
              <VButton
                className={`next-btn btn btn-blue`}
                text={"next"}
              />
            </div>
          </Swiper>
        </div>
        {/* Slider end */}
      </section>
      <VButtonBanner
        title="Are you a security player or an IT service provider?"
        description="Do you want to offer your customers a unique solution?"
      />
      <section className="case-study space-top space-bottom position-relative">
        <Container>
          <h6 className="text-blue text-uppercase fw-700 mb-4">
            INEFFECTIVE TRADITIONAL SOLUTIONS
          </h6>
          <h2 className="fw-700 text-dark mb-4">
            Are you a security player or an IT service provider?
          </h2>
          <Row className="align-items-center space-top-small justify-content-between">
            <Col lg={5}>
              <h3 className="text-dark fw-700 mb-4">Case Study</h3>
              <p className="fw-400 text-dark space-bottom-small mb-0">
                Learn how Veesion has helped address the security challenges of
                a variety of merchants, of all sizes and in all industries.
              </p>
              <VButton text="discover success" type="blue" />
            </Col>
            <Col lg={5}>
              <div className="case-study-row-wrapper">
                <div className="case-study-row case-study-shrinkage">
                  <div className="case-study-icon">
                    <TickCircle size="48" color="currentcolor" variant="Bold" />
                  </div>
                  <div className="case-study-content">
                    <h3 className="fw-800 text-dark mb-1">100 B. €</h3>
                    <p className="small text-dark mb-0">
                      Per year of shrinkage for physical retail
                    </p>
                  </div>
                </div>
                <div className="case-study-row case-study-invested">
                  <div className="case-study-icon">
                    <Video size="48" color="currentcolor" variant="Bulk" />
                  </div>
                  <div className="case-study-content">
                    <h3 className="fw-800 text-dark mb-1">100 B. €</h3>
                    <p className="small text-dark mb-0">
                      Per year invested in security by retailers
                    </p>
                  </div>
                </div>
                <div className="case-study-row case-study-theft">
                  <div className="case-study-icon">
                    <Monitor size="48" color="currentcolor" variant="Bulk" />
                  </div>
                  <div className="case-study-content">
                    <h3 className="fw-800 text-dark mb-1">&lt; 5 %</h3>
                    <p className="small text-dark mb-0">Of theft is detected</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>

        </Container>
      </section>
    </>
  );
};
