import { Col, Container, Image, Row } from "react-bootstrap";
import "./VWeAreCard.css";
import { VButton } from "../VButton/VButton";
export const VWeAreCard = (props) => {
  return (
    <>
      <section className="we-are-section">
        <Container>
          <div className="we-are-data position-relative">
            <Row>
              <Col xs="12" className="">
                <Image
                  src={props.src}
                  fluid
                  alt=""
                  className="align-self-end position-absolute partner-img"
                />
                <div className="partner-content-wrap ms-auto d-flex align-items-center justify-content-between">
                  <h2 className="fw-400 mb-xl-0 text-white text-center">
                    {props.title}
                  </h2>
                  <VButton
                    isLink={true}
                    path="/become-partners"
                    text={props.buttonText}
                    type="white"
                    className="w-max-content"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};
