import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { VHero } from "../../Components/VHero/VHero";
import Hero from "../../assets/images/cover/contact-us.jpg";
import VYTVideoPost from "../../Components/VYTVideoPost/VYTVideoPost";
import VYTVideoPostList from "../../Components/VYTVideoPostList/VYTVideoPostList";
import { pressVideoPostList } from "../../Data";
import "./Press.css";
import News from "../../assets/images/cover/news.jpg";
import VYTVideo from "../../Components/VYTVideo/VYTVideo";
const Press = () => {
  const [viewAll, setViewAll] = useState(false);
  return (
    <>
      <div className="press">
        <VHero className="top-texted" img={News} title="" curvedColor="#fff" />
        <Container>
          <div className="press-video">
            <VYTVideo url={`https://www.youtube.com/embed/bZhBWdlFyxA`} />
          </div>
          <h2 className="text-black text-center ">
            Medias Press talks <br /> about Veesion
          </h2>
          <Row>
            <Col lg={6} md={12}>
              <div className="mb-5 pb-3 mb-lg-0 pb-lg-0">
                {!viewAll && (
                  <VYTVideoPost
                    videoSrc={`https://www.youtube.com/embed/bZhBWdlFyxA`}
                  />
                )}
                {viewAll && (
                  <VYTVideoPostList
                    data={pressVideoPostList}
                    viewAll={viewAll}
                  />
                )}
              </div>
            </Col>
            <Col lg={6} md={12}>
              <VYTVideoPostList data={pressVideoPostList} viewAll={viewAll} />
            </Col>
          </Row>
        </Container>
        <div className="text-center">
          <button className="btn btn-blue" onClick={() => setViewAll(!viewAll)}>
            {viewAll ? "View Less" : "View All"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Press;
