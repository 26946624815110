import React from "react";
import "./VYTVideoPostList.css";
import { Col, Row } from "react-bootstrap";
import { VIcons } from "../VIcons/VIcons";
const VYTVideoPostList = ({ data, viewAll }) => {
  const { Calendar } = VIcons;

  return (
    <div className="VideoList ps-3">
      {(viewAll ? data : data.slice(0, 3)).map((videoList, index) => {
        let { videoSrc, date, heading } = videoList;

        return (
          <Row className=" mb-5 mb-md-3" key={index}>
            <Col sm={4} xs={12}>
              <div className="ratio ratio-4x3">
                <iframe
                  width="100%"
                  src={videoSrc}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col sm={8} xs={12}>
              <div className="ps-3 mt-4 mt-md-0">
                <div className="d-flex align-items-center mb-3">
                  <span className="me-3 d-flex"> {Calendar}</span>
                  <p className="small text-purple mb-0">{date}</p>
                </div>
                <div>
                  <p className="small text-black mt-3">{heading}</p>
                </div>
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default VYTVideoPostList;
