import React from "react";
import { VHero } from "../../Components/VHero/VHero";
import Hero from "../../assets/images/cover/pharmacises.jpg";
import VSurveillance from "../../Components/VSurveillance/VSurveillance";
import VSecure from "../../Components/VSecure/VSecure";
import VCommitment from "../../Components/VCommitment/VCommitment";
import {
  pharmacySurveillance,
  pharmacyKeyFigures,
  pharmacySecure,
  pharmacyCommitment,
  pharmacyVtheft,
  pharmacyAccordion,
} from "../../Data";
import VKeyFigure from "../../Components/VKeyFigure/VKeyFigure";
import VTheft from "../../Components/VTheft/VTheft";
import VAccordion from "../../Components/VAccordion/VAccordion";
import { VButtonBanner2 } from "../../Components/VButtonBanner/VButtonBanner2";
import { VSurvellianceVideo } from "../../Components/VSurvellianceVideo/VSurvellianceVideo";
import SupermarketVideo from "../../assets/video/veesion-videosurveillance-intelligente-background-video.mp4";

export const Pharmacies = () => {
  return (
    <>
      <VHero
        img={Hero}
        title="Video surveillance for pharmacies: Intelligent anti-theft system"
        curvedColor="#2F67F8"
        hasDescription={true}
        description="Video surveillance coupled with AI can be more than useful to detect thefts. Discover our product that automatically detects thefts in real time."
        hasButton
      />
      <VSurvellianceVideo
        title="Intelligent video surveillance for pharmacies"
        videoPoster="https://veesion.io/wp-content/uploads/2022/07/cover-videosurveillance-pharmacy-video.webp"
        video="https://veesion.io/wp-content/uploads/2022/05/UK-Pharma.mp4"
        description="Veesion is an artificial intelligence and security company. Founded in 2018, it detects theft behavior automatically and in real time in pharmacies. Our software is based on the latest advances in Deep Learning and is complementary to your existing surveillance cameras.You want to ensure the security of your pharmacy? Our anti-theft system is made for you!"
      />
      <div className="space-bottom-small">
        <VButtonBanner2
          title="Find out what Veesion can do for you"
          description="Do you have one or more pharmacies?Our team will get back to you within 48 hours."
        />
      </div>
      <VSurveillance data={pharmacySurveillance} />
      <VKeyFigure data={pharmacyKeyFigures} />
      <VTheft data={pharmacyVtheft} />
      <VSecure data={pharmacySecure} />
      <VCommitment data={pharmacyCommitment} />
      <VAccordion accordionList={pharmacyAccordion} defaultActiveKey={0} />
    </>
  );
};
