import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./VCommitment.css";
import {VIcons} from "../VIcons/VIcons";
const Commitment = ({data}) => {
  const {User, AI, VideoPlay, Technology} = VIcons;
  let {heading, contents, card1, card2, card3, card4} = data;

  return (
    <section className="commitment space-top position-relative">
      <svg class="curved-bg-top" width="1924" height="111" viewBox="0 0 1924 111" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1923.64 0.219604H0.458984V110.47C634.708 -21.6853 1289.39 -21.6853 1923.64 110.47V0.219604Z" fill="white"></path>
      </svg>
      <Container className="space-top-small">
        <Row className="gx-0 g-5">
          <Col lg={5}>
            <div className="commitment-content">
              <h2 className="fw-300 mb-5 pb-3 text-dark">{heading}</h2>
              {contents.map((content, index) => {
                return <p key={index}>{content}</p>;
              })}
            </div>
          </Col>
          <Col lg={7}>
            <div className="box-section ps-lg-5 ms-lg-4 text-white">
              <Row className="g-0">
                <Col md={4}>
                  <div className="item item1">
                    <span>{Technology}</span>
                    <p>{card1}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="item item2">
                    <span>{User}</span>
                    <p>{card2}</p>
                  </div>
                  <div className="item item3">
                    <span>{AI}</span>
                    <p>{card3}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="item item4">
                    <span>{VideoPlay}</span>
                    <p>{card4}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Commitment;
