import React from "react";
import { Container } from "react-bootstrap";
import VForm from "../../Components/VForm/VForm";
import VBanner from "../../Components/VBanner/VBanner";
import VAccordion from "../../Components/VAccordion/VAccordion";
import { FAQ, pharmacyCommitment, pharmacyKeyFigures } from "../../Data";
import VSecure from "../../Components/VSecure/VSecure";
import VCommitment from "../../Components/VCommitment/VCommitment";
import VSurveillance from "../../Components/VSurveillance/VSurveillance";
import VKeyFigure from "../../Components/VKeyFigure/VKeyFigure";

let content = `The mandatory data to process your contact request are marked with
an asterisk. VEESION processes the data collected to manage and
respond to your contact request. To find out more about the protection
of your data & to exercise your rights please consult our privacy policy.`;

export const Stylesheet = () => {
  return (
    <>
      <Container>
        <VBanner content={content} />
        <VAccordion accordionList={FAQ} defaultActiveKey={1} />
      </Container>
      <VKeyFigure data={pharmacyKeyFigures} />
      <VCommitment data={pharmacyCommitment} />
    </>
  );
};
