import React from "react";
import { VHero } from "../../Components/VHero/VHero";
import Hero from "../../assets/images/cover/hero-partners.jpg";
import VForm from "../../Components/VForm/VForm";
import { Container } from "react-bootstrap";
import Banner from "../../Components/VBanner/VBanner";
import "./BecomePartners.css";

let bannerContent = `The mandatory data to process your contact request are marked with
              an asterisk. VEESION processes the data collected to manage and
              respond to your contact request. To find out more about the
              protection of your data & to exercise your rights please consult
              our privacy policy.`;
export const BecomePartners = () => {
  return (
    <>
      <VHero
        className="top-texted"
        img={Hero} title="Become Partner" curvedColor="#fff" />
      <div className="form-banner-section">
        <Container>
          
          <Banner content={bannerContent} />
        </Container>
      </div>
    </>
  );
};
