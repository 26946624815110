import React from "react";

const VYTVideo = ({ url }) => {
  return (
    <div className="p-5 pb-0 bg-white   ">
      <div className="ratio ratio-16x9  ">
        <iframe
          width="100%"
          src={url}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VYTVideo;
