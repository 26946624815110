import React from "react";
import "./VYTVideoPost.css";
import { VIcons } from "../VIcons/VIcons";
import VReadMore from "../VReadMore/VReadMore";
const VYTVideoPost = ({ viewAll, videoSrc }) => {
  const { Calendar } = VIcons;
  return (
    <div className="video-post ">
      <iframe
        width="100%"
        height="345"
        src={videoSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <div className="video-post-date">
        <div className="d-flex align-items-center mb-3">
          <span className="me-3 d-flex"> {Calendar}</span>
          <p className="small text-purple mb-0"> Apr 14, 2023</p>
        </div>
      </div>
      <div>
        <p className="small text-dark small fw-400 ps-2">
          New York Post-Retailers busting thieves with facial recognition tech
          used by MSG’s James Dolan
        </p>
        <VReadMore
          content={`James Dolan has lately stirred controversy over his use of facial
recognition technology to James Dolan has lately stirred controversy over his use of facial
recognition technology to`}
          color="text-gray"
          ReadMoreColor="text-purple"
        />
      </div>
    </div>
  );
};

export default VYTVideoPost;
