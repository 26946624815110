import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { VButton } from "../VButton/VButton";
import "./VButtonBanner.css";

export const VButtonBanner2 = ({ title, description }) => {
  return (
    <section className="our-partner bg-partner space-top-small space-bottom-small">
      <Container>
        <Row className="align-items-center g-5">
          <Col lg={9}>
            <h2 className="fw-700 text-white mb-4">{title}</h2>
            <h3 className="fw-300 text-white mb-0">{description}</h3>
          </Col>
          <Col lg={3} className="text-lg-end">
            <VButton
              isLink={true}
              path="mailto:contact@veesion.ae"
              text="Contact us"
              type="white"
              className={"become-partner-btn"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
