import React from "react";
import VBanner from "../../Components/VBanner/VBanner";
import { VHero } from "../../Components/VHero/VHero";
import Hero from "../../assets/images/cover/contact-us.jpg";
import { Container } from "react-bootstrap";
import "./ContactUs.css";
import VForm from "../../Components/VForm/VForm";

let bannerContent = `VEESION processes the data collected to manage and respond to
your contact request. To find out more about the protection of your
data and to exercise your rights please consult our privacy policy.`;

export const ContactUs = () => {
  return (
    <>
      <VHero
        className="top-texted"
        img={Hero}
        title="Do you manage one or more stores?"
        curvedColor="#fff"
      />
      <section className="form-banner-section space-bottom-small">
        <Container>
        </Container>
      </section>
      <VBanner content={bannerContent} />
    </>
  );
};
