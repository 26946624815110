import React from "react";
import {VHero} from "../../Components/VHero/VHero";
import Hero from "../../assets/images/cover/supermarket.jpg";
import SupermarketVideo from "../../assets/video/veesion-videosurveillance-intelligente-background-video.mp4";
import { VButtonBanner2 } from "../../Components/VButtonBanner/VButtonBanner2";
import VSurveillance from "../../Components/VSurveillance/VSurveillance";
import {superMarketSurveillance, superMarketKeyFigures, supermarketSecure, superMarketCommitment} from "../../Data";
import Vsecure from "../../Components/VSecure/VSecure";
import VKeyFigure from "../../Components/VKeyFigure/VKeyFigure";
import VCommitment from "../../Components/VCommitment/VCommitment";
import {Container} from "react-bootstrap";
import "./Supermarkets.css";
import VTheft from "../../Components/VTheft/VTheft";
import {superMarketVtheft, supermarketAccordion} from "../../Data";
import VAccordion from "../../Components/VAccordion/VAccordion";
import {VSurvellianceVideo} from "../../Components/VSurvellianceVideo/VSurvellianceVideo";
export const Supermarkets = () => {
  return (
    <>
      <VHero
        img={Hero}
        title="Video surveillance for Supermarkets Intelligent anti-theft system"
        curvedColor="#2F67F8"
        hasDescription={true}
        description="Video surveillance in supermarkets is crucial for theft prevention.
        Discover our software, Veesion, that detects thefts automatically in real time."
        hasButton
      />
      <VSurvellianceVideo
        videoPoster="https://veesion.io/wp-content/uploads/2022/07/cover-videosurveillance.webp"
        title="Intelligent video surveillance for supermarkets"
        video="https://veesion.io/wp-content/uploads/2022/05/UKsupermarche%CC%81.mov"
        description="Veesion is a Paris-based company specialized in artificial
        intelligence and security. We have developed a gesture recognition
        technology. Our gesture recognition software uses the latest
        advances in Deep Learning to automatically detect gestures of
        theft in real time in supermarkets from existing surveillance
        cameras."
      />
      <VButtonBanner2
        title="Discover what Veesion can do for you"
        description="You have one or more supermarkets? Our team will get back to you within 48 hours."
      />
      <VSurveillance data={superMarketSurveillance} />
      <VKeyFigure data={superMarketKeyFigures} />
      <VTheft data={superMarketVtheft} />
      <Vsecure data={supermarketSecure} />
      <VCommitment data={superMarketCommitment} />
      <VAccordion accordionList={supermarketAccordion} defaultActiveKey={0} />
    </>
  );
};
