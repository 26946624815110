import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { VIcons } from "../VIcons/VIcons";
import "./VSurveillance.css";
const VSurveillance = ({ data }) => {
  const { Chart, Clock, Incognito } = VIcons;
  const { card1, card2, card3, contents, heading } = data;

  return (
    <section className="surveillance position-relative space-top-small space-bottom-small">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <h2 className="text-center text-dark fw-300 space-bottom-small">{heading}</h2>
          </Col>
        </Row>
        <Row className="gx-0">
          <Col lg={6}>
            <div className="box-list d-md-flex align-items-md-start h-100">
              <div className="box-list-card card1">
                <span>{Chart}</span>
                <p>{card1}</p>
              </div>
              <div className="box-list-card card2">
                <span>{Clock}</span>
                <p>{card2}</p>
              </div>
              <div className="box-list-card card3">
                <span>{Incognito}</span>
                <p>{card3}</p>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="surveillance-content">
              {contents.map((content) => {
                return <p>{content}</p>;
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VSurveillance;
