import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./VKeyFigure.css";
const VKeyFigure = ({ data }) => {
  return (
    <section className="keyFigure space-top-small space-bottom-small text-white text-center ">
      <Container>
        <h2 className="space-bottom"> Key Figures</h2>
        <Row className="keyCard g-0">
          {data.map((card, index) => {
            return (
              <Col md={4} className={`keyCard-card card${index}`} key={index}>
                <span className="d-inline-flex h1 fw-800 mb-5 pb-2" >{card.heading}</span>
                <p>{card.content}</p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default VKeyFigure;
